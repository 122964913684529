const awsmobile = {
  'aws_project_region': 'eu-central-1',
  'aws_cognito_identity_pool_id': 'eu-central-1:619acc85-61a4-4a22-9814-0533d6ae41df',
  'aws_cognito_region': 'eu-central-1',
  'aws_user_pools_id': 'eu-central-1_TiDTltKXw',
  'aws_user_pools_web_client_id': '69j9tnsthtqsj7h7d6lcjf77rv',
  'oauth': {},
  'aws_cognito_username_attributes': [
    'EMAIL',
  ],
  'aws_cognito_social_providers': [],
  'aws_cognito_signup_attributes': [
    'EMAIL',
  ],
  'aws_cognito_mfa_configuration': 'OFF',
  'aws_cognito_mfa_types': [
    'SMS',
  ],
  'aws_cognito_password_protection_settings': {
    'passwordPolicyMinLength': 8,
    'passwordPolicyCharacters': [],
  },
  'aws_cognito_verification_mechanisms': [
    'EMAIL',
  ],
  'aws_appsync_graphqlEndpoint': 'https://kdusrnea55cqnb63prczl5rtzq.appsync-api.eu-central-1.amazonaws.com/graphql',
  'aws_appsync_region': 'eu-central-1',
  'aws_appsync_authenticationType': 'AMAZON_COGNITO_USER_POOLS',
  'aws_appsync_apiKey': 'da2-677kf5iwuzebpkijfkt5fv4h64',
  'aws_cloud_logic_custom': [
    {
      'name': 'AdminQueries',
      'endpoint': 'https://b3av5cfsd7.execute-api.eu-central-1.amazonaws.com/dev',
      'region': 'eu-central-1',
    },
    {
      'name': 'chatHook',
      'endpoint': 'https://iijreicg91.execute-api.eu-central-1.amazonaws.com/dev',
      'region': 'eu-central-1',
    },
    {
      'name': 'verifyEmailAddress',
      'endpoint': 'https://2rn47hvtsa.execute-api.eu-central-1.amazonaws.com/dev',
      'region': 'eu-central-1',
    },
  ],
  'aws_mobile_analytics_app_id': '547849b6a7c54907970a023eca1e572f',
  'aws_mobile_analytics_app_region': 'eu-central-1',
  'aws_user_files_s3_bucket': 'lifebonusdev-dev',
  'aws_user_files_s3_bucket_region': 'eu-central-1',
};


export default awsmobile;

